import React, { useContext } from "react";
import { graphql } from "gatsby";
import Layout from "../components/layout";
import { palette_container } from "../styles/modules/utilities.module.css";
import { MDXRenderer } from "gatsby-plugin-mdx";
import BreadCrumbs from "../components/BreadCrumps";
import LinksBox from "../components/LinksBox";
import { transformSlugToArrBreadcrumbs } from "../helpers/transformSlugToArr";
import Color from "../components/GeneratePalette/Color";
import { WebPageJsonLD } from "../components/SEO/WebPageJsonLD";
import { BasicHeadTags } from "../components/SEO/BasicHeadTags";
import { convertRenderColor } from "../helpers/convertRenderColor";
import { ContextFormatColor } from "../context/ContextFormatColor";
// import HrefLang from "../components/SEO/HrefLang";
// import { allLanguagesLinks } from "../helpers/consts";
import { url } from "../../config/website";
import { ContextLocale } from "../context/ContextLocale";
import { content_wrapper } from "../styles/modules/content.module.css";
// import AdWithPlaceholder from "../components/Ads/AdWithPlaceholder";
import AdManaWithPlaceholder from "../components/Ads/AdManaWithPlaceHolder";

// import ColorSchemeAd from "../components/Ads/ColorSchemeAd";

function PageTonoPalette({ data, location }) {
  const { setLang } = useContext(ContextLocale);
  const { mdx } = data;

  setLang(mdx.frontmatter.locale ? mdx.frontmatter.locale : "es");

  const { format } = useContext(ContextFormatColor);

  const renderPalette = mdx.frontmatter.hexCodes.map(color =>
    convertRenderColor({ color: color }, format)
  );

  const embeddedImagesObject =
    mdx.frontmatter.embeddedImages &&
    mdx.frontmatter.embeddedImages.reduce((images, image) => {
      images[image.name.replace(/-/g, "_")] = images[image.name] || {
        ...image.childImageSharp,
      };

      return images;
    }, {});

  const breadCrumbsList = transformSlugToArrBreadcrumbs(
    mdx.frontmatter.slug,
    mdx.frontmatter.locale ? mdx.frontmatter.locale : "es"
  );

  return (
    <Layout locale={mdx.frontmatter.locale}>
      <div className={palette_container}>
        {renderPalette.map(color => (
          <Color
            path={location.pathname}
            key={color}
            color={color}
            withLinkColor={true}
          />
        ))}
      </div>

      <AdManaWithPlaceholder
        path="/22250597679/ptdc_banner_bottom"
        size={[
          [300, 250],
          [970, 250],
          [728, 250],
          [970, 90],
          [728, 90],
        ]}
        id="ptdc_banner_bottom"
      />
      {/* <AdWithPlaceholder
        ins={`<!-- ptdc-color -->
        <ins class="adsbygoogle"
             style="display:block"
             data-ad-client="ca-pub-3833126112632805"
             data-ad-slot="5789449190"
             data-ad-format="auto"
             data-full-width-responsive="true"></ins>`}
      /> */}
      <BreadCrumbs breadCrumbsList={breadCrumbsList} />

      <article className={content_wrapper}>
        <h1>{mdx.frontmatter.title} </h1>

        <MDXRenderer embeddedImages={embeddedImagesObject}>
          {mdx.body}
        </MDXRenderer>
      </article>
      <LinksBox locale={mdx.frontmatter.locale} slug={mdx.frontmatter.slug} />
    </Layout>
  );
}

export const Head = ({ data }) => {
  const { mdx } = data;
  const breadCrumbsList = transformSlugToArrBreadcrumbs(
    mdx.frontmatter.slug,
    mdx.frontmatter.locale ? mdx.frontmatter.locale : "es"
  );
  // const [links] = allLanguagesLinks.tons.filter(elem =>
  //   elem[mdx.frontmatter.locale]
  //     ? elem[mdx.frontmatter.locale].includes(mdx.frontmatter.slug)
  //     : null
  // );
  // <HrefLang links={links} />
  // canonicalUrl={`${url}${mdx.frontmatter.slug}`}
  return (
    <>
      <BasicHeadTags
        title={mdx.frontmatter.metaTitle}
        description={mdx.frontmatter.metaDescription}
        type="article"
        image={mdx.frontmatter.featuredImage.publicURL}
        slug={mdx.frontmatter.slug}
        canonicalUrl={`${url}${mdx.frontmatter.slug}`}
      />

      <WebPageJsonLD
        title={mdx.frontmatter.title}
        description={mdx.frontmatter.metaDescription}
        imagePublicURL={mdx.frontmatter.featuredImage.publicURL}
        imageWidth={
          mdx.frontmatter.featuredImage.childImageSharp.original.width
        }
        imageHeight={
          mdx.frontmatter.featuredImage.childImageSharp.original.height
        }
        slug={mdx.frontmatter.slug}
        date={mdx.frontmatter.date}
        modified={mdx.frontmatter.modified}
        breadcrumbs={breadCrumbsList}
      />
    </>
  );
};

export default PageTonoPalette;

export const data = graphql`
  query ($id: String!) {
    mdx(id: { eq: $id }) {
      body
      id
      frontmatter {
        title
        slug
        metaTitle
        metaDescription
        hexCodes
        locale
        date
        modified
        featuredImage {
          publicURL
          childImageSharp {
            original {
              height
              width
            }
          }
        }
        embeddedImages {
          name
          childImageSharp {
            gatsbyImageData(
              layout: CONSTRAINED
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
      }
    }
  }
`;
